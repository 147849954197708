import { Node } from "react-flow-renderer";

export default [
    //   {
    //     id: "2",
    //     data: { label: "Group A" },
    //     position: { x: 100, y: 100 },
    //     className: "light",
    //     style: { backgroundColor: "rgba(255, 0, 0, 0.2)", width: 200, height: 200 },
    //   },
    //   {
    //     id: "2a",
    //     data: { label: "Node A.1" },
    //     position: { x: 10, y: 50 },
    //     parentNode: "2",
    //   },
] as Node[];
