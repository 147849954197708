import {
  BsMailbox,
  BsFileBinary,
  BsFiles,
  BsFiletypeSql,
  BsArchive,
  BsCaretLeft,
  BsCaretRight,
  BsCaretUp,
  BsCaretDown,
  BsThreeDots,
  BsList,
  BsSliders,
  BsPaletteFill,
  BsFileFontFill,
  BsXCircleFill,
  BsDatabase,
  BsHddFill,
  BsGlobe,
  BsClock,
  BsFileCode,
  BsFileEarmarkArrowUp,
  BsInfoCircle,
  BsDiagram2,
  BsSliders2,
  BsCardChecklist,
  BsFiletypeXml,
  BsBoxes,
  BsListCheck,
  BsFiletypeCsv,
  BsApp,
  BsFilePlus,
  BsEnvelopeAt,
  BsArrowsAngleExpand,
  BsArrowsCollapse,
  BsServer,
  BsDiagram2Fill,
  BsGear,
  BsGraphDown,
  BsPlayFill,
  BsStopFill,
  BsExclamationTriangleFill,
  BsFolder,
  BsPerson,
  BsPen,
  BsPenFill,
  BsBellFill,
  BsArrowsExpandVertical,
  BsIncognito,
  BsArrowDownUp,
  BsUpload,
  BsCode,
} from "react-icons/bs";

import { RiAlertLine } from "react-icons/ri";
import {
  FaGlobe,
  FaFileExport,
  FaCodeBranch,
  FaChevronDown,
  FaChevronUp,
  FaPen,
} from "react-icons/fa";

export const connectionsIcons: any = {
  nodeIcons: {
    send: <BsMailbox></BsMailbox>,
    db2: <BsHddFill></BsHddFill>,
    fileservice: <BsFileBinary></BsFileBinary>,
    file_system: <BsFiles></BsFiles>,
    ftp: "ftp",
    odbc: <BsDatabase></BsDatabase>,
    restservice: "r",
    sql: <BsFiletypeSql />,
    ssc_webservice: <BsGlobe></BsGlobe>,
    webservice: <FaGlobe></FaGlobe>,
    sap: "s",
    uipath: "u",
    azure_resources: "a",
    access_procure_wizard: "aw",
    access_epos: "ae",
    grc_webservice: "grc",
    //function
    archive: <BsArchive></BsArchive>,
    delay: <BsClock></BsClock>,
    file_operation: <BsFileCode></BsFileCode>,
    filetrigger: <FaFileExport></FaFileExport>,
    loadfragment: <BsFileEarmarkArrowUp></BsFileEarmarkArrowUp>,
    log: <BsInfoCircle></BsInfoCircle>,
    logicops: <BsDiagram2></BsDiagram2>,
    parametizer: <BsSliders2></BsSliders2>,
    scheduletrigger: <BsCardChecklist></BsCardChecklist>,
    script: <FaCodeBranch></FaCodeBranch>,
    xmlsplitter: <BsFiletypeXml></BsFiletypeXml>,
    transformer: <BsBoxes></BsBoxes>,
    validator: <BsListCheck></BsListCheck>,
    //input
    input_csv: <BsFiletypeCsv></BsFiletypeCsv>,
    empty: <BsApp></BsApp>,
    input_flatfile: <BsFilePlus>,</BsFilePlus>,
    input_xml: <BsFiletypeXml></BsFiletypeXml>,
    //output
    email: <BsEnvelopeAt></BsEnvelopeAt>,
    output_flatfile: <FaFileExport></FaFileExport>,
    output_xml: <BsFiletypeXml></BsFiletypeXml>,
  },
  serverMenuIcons: {
    server: <BsServer></BsServer>,
    flows: <BsDiagram2Fill></BsDiagram2Fill>,
    stats: <BsGraphDown></BsGraphDown>,
    settings: <BsGear></BsGear>,
    security: <BsIncognito></BsIncognito>,
    alert: <RiAlertLine></RiAlertLine>,
  },
  serverButtonsIcons: {
    play: <BsPlayFill></BsPlayFill>,
    stop: <BsStopFill></BsStopFill>,
    kill: <BsExclamationTriangleFill></BsExclamationTriangleFill>,
  },
  profile: <BsPerson></BsPerson>,
  leftCaret: <BsCaretLeft></BsCaretLeft>,
  rightCaret: <BsCaretRight></BsCaretRight>,
  upCaret: <BsCaretUp></BsCaretUp>,
  downCaret: <BsCaretDown></BsCaretDown>,
  arrowDown: <FaChevronDown></FaChevronDown>,
  arrowUp: <FaChevronUp></FaChevronUp>,
  dotsView: <BsThreeDots></BsThreeDots>,
  lineView: <BsList></BsList>,
  settings: <BsSliders></BsSliders>,
  pallete: <BsPaletteFill></BsPaletteFill>,
  text: <BsFileFontFill></BsFileFontFill>,
  delete: <BsXCircleFill></BsXCircleFill>,
  expand: <BsArrowsAngleExpand></BsArrowsAngleExpand>,
  downUpArrow: <BsArrowDownUp></BsArrowDownUp>,
  collapse: <BsArrowsCollapse></BsArrowsCollapse>,
  folder: <BsFolder></BsFolder>,
  editOff: <BsPenFill></BsPenFill>,
  editOn: <BsPen></BsPen>,
  bell: <BsBellFill></BsBellFill>,
  resize: <BsArrowsExpandVertical></BsArrowsExpandVertical>,
  pen: <FaPen></FaPen>,
  upload: <BsUpload></BsUpload>,
  code: <BsCode></BsCode>,
};
